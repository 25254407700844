body {
    background: #cacaca;
    color: #212121;
}

body .page {
    position: relative;
    z-index: 10;
    text-align: left;
}

.section0 {
    position: relative;
    z-index: 20;
}

.section0 .container{
    font-size: 15px;
}

.section0 .container .box-info-renee-title{
    font-size: 4em;
    margin-top: calc((100vw * 315 / 560) / 8);
}

.section0 .container .box-info-renee-subtitle{
    font-size: 3em;
    font-weight: 400;
}

.default-video {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 10;
    opacity: 0.55;
    width: 100%;
    height: calc(100vw * 315 / 560);
}

.default-video iframe {
    display: block;
    width: 100%;
    height: calc(100vw * 315 / 560);
    z-index: 12;
    position: absolute;
    top: 0
}

body .section-custom-1 {
    min-height: calc(100vw * 315 / 560);
    height: calc(100vw * 315 / 560);
}

body a.icon:hover,
body a.rd-nav-link:hover {
    color: #e30016 !important;
}

body .ui-to-top,
body .ui-to-top:focus,
body .ui-to-top:active {
    background-color: #e30016 !important;
}

body .footer-creative-title,
body .cssload-circle .cssload-inner {
    border-color: #e30016 !important;
}

body .thumbnail-classic {
    cursor: pointer;
}

body .thumbnail-classic .icon {
    opacity: 0;
    transition: 1s;
}

body .thumbnail-classic:hover .icon {
    color: #e30016 !important;
    opacity: 0.85;
    transition: 1s;
}

.page-index ul {
    display: block;
    margin-top: 20px;
}

.page-index ul li {
    text-indent: -24px;
    padding-left: 24px;
}

.page-index ul li:before {
    position: relative;
    display: inline-block;
    left: 0;
    top: 0;
    content: '';
    height: 9px;
    width: 9px;
    margin-right: 15px;
    border-radius: 50%;
    background: #212121;
}

.section-video .thumbnail-classic {
    position: relative;
}

.section-video iframe {
    position: relative;
    z-index: 10;
    display: block;
    width: 100%;
    aspect-ratio: auto 16 / 9;
}

.section-video a {
    position: absolute;
    z-index: 15;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.section-video a:hover {
    background-color: transparent;
}

.context-dark .rd-navbar-static .rd-nav-item.active .rd-nav-link, .bg-gray-700 .rd-navbar-static .rd-nav-item.active .rd-nav-link, .bg-gray-3 .rd-navbar-static .rd-nav-item.active .rd-nav-link, .bg-brown-1 .rd-navbar-static .rd-nav-item.active .rd-nav-link, .bg-primary .rd-navbar-static .rd-nav-item.active .rd-nav-link {
    color: #fff;
}

body ul.rd-navbar-nav li.rd-nav-item a.active-lapiech {
    color: #e30016;
}

.rd-navbar-fixed .rd-nav-item:hover .rd-nav-link, .rd-navbar-fixed .rd-nav-item.focus .rd-nav-link, .rd-navbar-fixed .rd-nav-item.active .rd-nav-link, .rd-navbar-fixed .rd-nav-item.opened .rd-nav-link {
    background-color: transparent;
    color: #151515;
}

footer .contacts-holly li + li{
    margin-top: 10px;
}

header ul.social li.list-inline-item a.icon,
footer ul.list-social li a.icon{
    font-size: 2.5em;
    line-height: 2em;
}

header .rd-navbar-static .rd-nav-link,
header .rd-navbar-fixed .rd-nav-link{
    font-size: 16px
}


.plyr__controls__item.plyr__menu button.plyr__control{
    display: none;
}

body nav.rd-navbar--is-stuck li.rd-nav-item-home{
    display: block;
}
body li.rd-nav-item-home{
    position: absolute;
    left: 10px;
    top: 10px;
    display: none;
}

body li.rd-nav-item-home img{
    height: 50px;
}

.first-page{
    background-image: url(../images/bardabusz_team_1200.jpg);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}


.section-person a.button{
    position: relative;
    padding-left: 70px;
}
.section-person a.button span{
    font-size: 3em;
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(-50%, -50%);
}

@media (max-width: 1199px) {
    .section0 .container{
        font-size: 12px;
    }

    .default-video {
        top: 57px;
    }

    body ul.rd-navbar-nav li.rd-nav-item a.active-lapiech {
        background-color: #e30016 !important;
        color: #fff !important;;
    }

    .thumbnail-classic .thumbnail-classic-caption{
        display: none;
    }
}

@media (max-width: 600px) {
    .section0 .container{
        font-size: 10px;
    }

    .section0 .container .box-info-renee-title{
        margin-top: calc((100vw * 315 / 560) / 20);
    }
}

@media (max-width: 450px) {
    .section0 .container .box-info-renee-title{
        font-size: 2.2em;
    }
}